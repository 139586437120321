import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './GalleryPage.css';
import { ArrowBigRight } from 'lucide-react';

const sections = [
  { name: 'Our Clinic ', 
    count: 8, 
    path: '/Our-Clinic', 
    img: '/gallery/our-clinic/clinic-1.jpeg',
    logo: 'https://i.ibb.co/n3MPJjs/dm-logo.png'
  },
  { name: 'Indian Coast Guard', 
    count: 11, 
    path: '/Indian-Coast-Guard', 
    img: '/gallery/indian-coast-guard/c-1.jpeg',
    logo: 'https://i.ibb.co/B4qdw95/Whats-App-Image-2024-08-21-at-22-41-43.jpg'
  },
  { name: 'ONGC', 
    count: 6, 
    path: '/ONGC', 
    img: '/gallery/ongc/ongc-1.jpeg',
    logo: 'https://i.ibb.co/0VSZkPv/ongc.png'
  },
  { name: 'GAIL', 
    count: 6, 
    path: '/GAIL', 
    img: '/gallery/gail/gail-1.jpeg',
    logo: 'https://i.ibb.co/JmVnnmB/gail-logo.png'
  },
  { name: 'Pajanco', 
    count: 6, 
    path: '/PAJANCO', 
    img: '/gallery/pajanco/pa-1.jpeg',
    logo: 'https://i.ibb.co/RNjy4H3/panjanco.jpg'
  },
  { name: "Women Polytechnic College", 
    count: 11, 
    path:"/Women-Polytechnic-College", 
    img: "/gallery/polytechnic/p-1.jpeg",
    logo: 'https://i.ibb.co/mFSZswK/polytechnic.jpg'
  },
];

//major update
const GalleryPage = () => {
  const sectionRefs = useRef([]);
  const lastScrollTop = useRef(0);
  const navigate = useNavigate();
  
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop.current) {
        sectionRefs.current.forEach((ref) => {
          if (ref) {
            const rect = ref.getBoundingClientRect();
            if (rect.top < window.innerHeight) {
              ref.classList.add('show');
            }
          }
        });
      }
      lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSeeMoreClick = () => {
    navigate('/nirmala-rani-girls-hr-sec-school'); // Navigate to the desired section
  };

  return (
    <div className="gallery-container">
      <div className="gallery-grid">
        <div className="gallery-title">
          <img src="https://i.ibb.co/Lk3qHD9/gallery-icon.png" alt="gallery"/>
          <h2>DM Nature's Gallery</h2>
        </div>
        {sections.map((section, index) => (
          <Link
            to={section.path}
            key={section.name}
            className={`gallery-section gallery-section-${index + 1}`}
            ref={(el) => (sectionRefs.current[index] = el)}
          >
            <div className="gallery-image-wrapper">
              <img src={section.img} alt={section.name} className="gallery-image" />
              <div className="gallery-overlay">
                <div className="gallery-info">
                  <div className="gallery-info-content">
                    <img className={`logo-${index+1}`} src={section.logo} alt={`logo-${index+1}`} />
                    <h2>{section.name}</h2>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}
        <div className="see-more" onClick={handleSeeMoreClick}>
          <span>See More</span>
          <ArrowBigRight size={70} className="see-more-icon" />
        </div>
      </div>
    </div>
  );
};

export default GalleryPage;
