import React from 'react';
import Masonry from 'react-masonry-css';
import './ArticleListPage.css'; // Import the CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons'; // Example icon

const articles = [
   
  {
    id: 1,
    title: "The Best and Worst States for Women’s Healthcare",
    description: "A new report states that U.S. women’s health is in a “perilous place.”",
    content: "Full article content goes here...",
    imageUrl: "https://i.ibb.co/QjdvH6Q/article-1.jpg",
  },
  {
    id: 2,
    title: "How to Become the Boss of Your Emotions",
    description: "The ability to experience and express emotions is essential for overall health.",
    content: "Full article content goes here...",
    imageUrl: "https://i.ibb.co/N9Cs3Vn/article-2.jpg",
  },
  {
    id: 3,
    title: "Lowering Your High Cholesterol: 6 Exercises That Will Pay Off",
    description: "Besides diet, movement is an effective strategy for managing high cholesterol.",
    content: "Full article content goes here...",
    imageUrl: "https://i.ibb.co/28qtWDn/news-article-1.jpg",
  },
  {
    id: 4,
    title: "Lowering Your High Cholesterol: 6 Exercises That Will Pay Off",
    description: "Besides diet, movement is an effective strategy for managing high cholesterol.",
    content: "Full article content goes here...",
    imageUrl: "https://i.ibb.co/bX8G8Wd/news-article-2.jpg",
  },
];

const ArticleListPage = () => {
    const breakpointColumnsObj = {
        default: 2,
        768: 2,
        480: 1
    };

    return (
        <div className="article-list-page">
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {articles.map(article => (
                    <div className="article-item" key={article.id}>
                        <img src={article.imageUrl} alt={article.title} />
                        {/* <h3>{article.title}</h3>
                        <p>{article.description}</p> */}
                    </div>
                ))}
            </Masonry>
        </div>
    );
};

export default ArticleListPage;
