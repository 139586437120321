import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './FloatingWhatsAppIcon.css'; 

const FloatingWhatsAppIcon = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = '86808 88963'; 
    const message = 'Hello, I have a question about your services.'; 
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    window.open(url, '_blank');
  };

  return (
    <div className="whatsapp-icon" onClick={handleWhatsAppClick}>
      <FaWhatsapp size={30} />
    </div>
  );
};

export default FloatingWhatsAppIcon;
