import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './About.css';

const About = () => {
  const [activeTab, setActiveTab] = useState('aim');
  const backgroundImageUrl = 'https://example.com/your-background-image.jpg'; 
  const carouselImages = [
    'https://i.ibb.co/hRGgVMN/about-us.jpg',
    'https://i.ibb.co/YTw1mJJ/accupunture.jpg',
    'https://i.ibb.co/WgcjT91/yoga.jpg',
  ];

  const tabContent = {
    aim: [
      'Bringing complete healing with natural modalities.',
      'Avoiding symptomatic relief of any problem rather help by preventing complications in long term.',
      'Bringing changes to dietary habits and lifestyle modification.',
    ],
    vision: [
      'Our vision is to encourage awareness on disease-specific treatment regimen through a combination of inter related holistic systems – Naturopathy, Yoga, Diet therapy, as the most competent solution for prolonged health and fitness.'
    ],
    mission: [
      'Our mission is “Promotion of Holistic Health Care through Naturopathy, Yoga therapy, Diet therapy and other drugless systems of medicines for the benefit of mankind.'
    ]
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-us-container">
      <div
        className="about-us-background"
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      >
        <div className="about-us-content">
          <div className="carousel-container">
            {/* <Carousel
              autoPlay
              infiniteLoop
              showThumbs={false}
              showStatus={false}
              interval={3000}
              transitionTime={600}
            >
              {carouselImages.map((url, index) => (
                <div key={index}>
                  <img src={url} alt={`Carousel Image ${index + 1}`} />
                </div>
              ))}
            </Carousel> */}
            <img src="https://i.ibb.co/hRGgVMN/about-us.jpg" alt="about-us"/>
            <div className="about-buttons">
              <button
                className={activeTab === 'aim' ? 'active' : ''}
                onClick={() => setActiveTab('aim')}
              >
                Aim
              </button>
              <button
                className={activeTab === 'vision' ? 'active' : ''}
                onClick={() => setActiveTab('vision')}
              >
                Vision
              </button>
              <button
                className={activeTab === 'mission' ? 'active' : ''}
                onClick={() => setActiveTab('mission')}
              >
                Mission
              </button>
            </div>
            <div className={`tab-content ${activeTab}`}>
              <h2>{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}</h2>
              {activeTab === 'aim' ? (
                <ul>
                  {tabContent.aim.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              ) : (
                tabContent[activeTab].map((item, index) => <p key={index}>{item}</p>)
              )}
            </div>
          </div>
          <div className="about-us-text">
            <h1>About Us</h1>
            <p>
              DM Nature Cure was started by Dr. M. Dhanalakshmi, first
              complementary and preventive Medical Center opened in Karaikal,
              Puducherry, India on 11th May 2023 with intention to recover and
              rebuild health of the individual and enrich healthy society.
            </p>
            <p>
              DM
              Nature cure and wellness center provides Naturopathy, Ozone Therapy,
              Detoxification Therapy, Rejuvenation Therapy, Cupping Therapy,
              Accupunture Therapy, Mud Therapy, Diet Therapy,
              Therapeutic Yoga, Underwater Massage, Therapeutic Massage, Pain
              Management, Life style modification etc.
            </p>
            <p>
              We take the human body as a
              whole and treat the person completely with the help of five natural
              elements Earth, Water, Air, Fire and Ether. Naturopathic practice is
              based on faith in the body’s ability to heal by its own internal
              healing forces. By stimulating body’s inherent power to heal we help
              to overcome Physical and Mental Ailments.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
