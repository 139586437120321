import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import "./BookingForm.css";

const BookingForm = ({ setShowConsultationButton }) => {
  const [date, setDate] = useState(null); 
  const [time, setTime] = useState("");
  const [availability, setAvailability] = useState([]);
  const [loading, setLoading] = useState(false); 
  const [userDetails, setUserDetails] = useState({
    name: "",
    age: "",
    city: "",
    email: "",
    phone: "",
  });
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [agreeCancellation, setAgreeCancellation] = useState(false);
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false); 
  const navigate = useNavigate();

  useEffect(() => {
    setShowConsultationButton(false);
    return () => {
      setShowConsultationButton(true);
    };
  }, [setShowConsultationButton]);

  useEffect(() => {
    if (date) {
      setLoading(true);
      checkAvailability(date);
    }
  }, [date]);

  const checkAvailability = async (selectedDate) => {
    try {
      const formattedDate = moment(selectedDate)
        .tz("Asia/Kolkata")
        .format("DD/MM/YYYY");
      const response = await axios.post(
        //"http://localhost:8081/api/check-availability",
        "https://steadfast-blessing-production.up.railway.app/api/check-availability",
        { date: formattedDate }
      );
      setAvailability(response.data);
    } catch (error) {
      console.error("Error checking availability", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePayment = async () => {
    if (
      !date ||
      !time ||
      !userDetails.name ||
      !userDetails.age ||
      !userDetails.city ||
      !userDetails.email ||
      !userDetails.phone ||
      !agreePrivacy ||
      !agreeCancellation
    ) {
      alert(
        "Please fill all the fields and agree to the policies before proceeding to payment."
      );
      return;
    }

    const formattedDate = moment(date).tz("Asia/Kolkata").format("DD/MM/YYYY");

    const options = {
      key: "rzp_test_fCOdlv2fYnbU2Z",
      amount: 50000,
      currency: "INR",
      name: "GUT HEALTH",
      description: "Consultation Fee",
      handler: async (response) => {
        try {
          setShowModal(true); // Show the waiting modal
          await axios.post(
            // "http://localhost:8081/api/book"
            "https://steadfast-blessing-production.up.railway.app/api/book", {
            ...userDetails,
            date: formattedDate,
            time,
            paymentId: response.razorpay_payment_id,
          });
          await axios.post(
            // "http://localhost:8081/api/send-email",
            "https://steadfast-blessing-production.up.railway.app/api/send-email", {
            ...userDetails,
            date: formattedDate,
            time,
            paymentId: response.razorpay_payment_id,
          });
          setShowModal(false); // Hide the waiting modal
          setMessage("Thank you for booking! Your payment was successful.");
          window.scrollTo(0, 0); // Scroll to the top of the page
        } catch (error) {
          console.error("Error booking consultation", error);
          setShowModal(false); // Hide the waiting modal in case of error
        }
      },
      prefill: {
        name: userDetails.name,
        email: userDetails.email,
        contact: userDetails.phone,
      },
      theme: {
        color: "#F37254",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const convertToLocalTime = (time) => {
    return moment.tz(time, "HH:mm", "Asia/Kolkata").format("h:mm A");
  };

  const filterPastTimes = (times) => {
    const currentDate = new Date();
    if (date && isCurrentDate(date)) {
      const currentTime = moment(currentDate).tz("Asia/Kolkata").format("HH:mm");
      return times.filter((slot) => slot.time >= currentTime);
    }
    return times;
  };

  const isCurrentDate = (tileDate) => {
    const currentDate = new Date();
    return (
      tileDate.getDate() === currentDate.getDate() &&
      tileDate.getMonth() === currentDate.getMonth() &&
      tileDate.getFullYear() === currentDate.getFullYear()
    );
  };

  return (
    <div className="booking-form">
      <h1>Book Your Slot</h1>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <div className="spinner"></div>
            <p>Waiting for Confirmation...</p>
          </div>
        </div>
      )}
      {message ? (
        <div className="message">
          <p>{message}</p>
          <p>We look forward to serving you.</p>
          <p>Stay healthy and take care!</p>
          <p>For any inquiries, feel free to contact us.</p>
          <p>Thank you!</p>
          <button onClick={() => (window.location.href = "/")}>
            Home Page
          </button>
        </div>
      ) : (
        <div className="form-container">
          <div className="form-input-img-container">
            <div className="inputs-container">
              <input
                type="text"
                placeholder="Name"
                required
                onChange={(e) =>
                  setUserDetails({ ...userDetails, name: e.target.value })
                }
              />
              <input
                type="number"
                placeholder="Age"
                required
                onChange={(e) =>
                  setUserDetails({ ...userDetails, age: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="City"
                required
                onChange={(e) =>
                  setUserDetails({ ...userDetails, city: e.target.value })
                }
              />
              <input
                type="email"
                placeholder="Email"
                required
                onChange={(e) =>
                  setUserDetails({ ...userDetails, email: e.target.value })
                }
              />
              <input
                type="tel"
                placeholder="Mobile Number"
                required
                onChange={(e) =>
                  setUserDetails({ ...userDetails, phone: e.target.value })
                }
              />
              {/* <div className="policy-checkbox">
                <input
                  type="checkbox"
                  id="privacy-policy"
                  checked={agreePrivacy}
                  onChange={(e) => setAgreePrivacy(e.target.checked)}
                />
                <label htmlFor="privacy-policy">
                  I agree to the{" "}
                  <span
                    className="policy-link"
                    onClick={() => navigate("/privacy-policy")}
                  >
                    privacy policy
                  </span>{" "}
                  of Dm Nature
                </label>
              </div> */}
            </div>
            <div className="image-container">
              <img src="https://i.ibb.co/SdjGzFQ/landpage.jpg" alt="image" />
            </div>
          </div>
          <div className="calendar-and-slots">
            <div className="left-side">
              <p className="warning-message">
                * You are not able to book the current date. If you want, contact
                through the mobile or email.
              </p>
              <div className="calendar-container">
                <Calendar
                  onChange={setDate}
                  value={date}
                  tileClassName={({ date, view }) =>
                    view === "month" &&
                    date.getTime() === new Date().setHours(0, 0, 0, 0) &&
                    date.getTime() !== new Date().setHours(0, 0, 0, 0)
                      ? "react-calendar__tile--highlight"
                      : null
                  }
                  tileDisabled={({ date }) => date < new Date().setHours(0, 0, 0, 0)}
                />
              </div>
            </div>
            <div className="right-side">
              <div className="time-slots">
                {loading ? (
                  <div className="spinner"></div>
                ) : date ? (
                  filterPastTimes(availability).map((slot) => (
                    <button
                      key={slot.time}
                      className={`slot-button ${
                        slot.booked ? "booked" : ""
                      } ${time === slot.time ? "selected" : ""}`}
                      onClick={() => setTime(slot.time)}
                      disabled={slot.booked}
                    >
                      {convertToLocalTime(slot.time)}
                    </button>
                  ))
                ) : (
                  <p>Please select a date for our availability time</p>
                )}
              </div>
              <div className="policy-checkbox">
                <input
                  type="checkbox"
                  id="cancellation-policy"
                  checked={agreeCancellation}
                  onChange={(e) => setAgreeCancellation(e.target.checked)}
                />
                <label htmlFor="cancellation-policy">
                  I agree to the{" "} 
                  <span
                    className="policy-link"
                    onClick={() => navigate("/privacy-policy")}
                  >
                    terms and condition
                  </span>{" and "}
                  <span
                    className="policy-link"
                    onClick={() => navigate("/cancellation-refund")}
                  >
                    cancellation policy
                  </span>{" "}
                  of Dm Nature Cure
                </label>
              </div>
            </div>
          </div>
          <button onClick={handlePayment} className="submit-button">
            Proceed to Payment
          </button>
        </div>
      )}
    </div>
  );
};

export default BookingForm;
