import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import './AboutPreview.css';

const AboutPreview = () => {
  const navigate = useNavigate();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const localImage = "/homepage/about-us-preview.png";

  return (
    <div ref={ref} className={`shop-local-container ${inView ? 'visible' : ''}`}>
      <div className="image-container">
        <img src={localImage} alt="Local shop" />
      </div>
      <div className="text-container">
        <h1>About Us</h1>
        <p>
          DM Nature Cure was started by Dr. M. Dhanalakshmi, first
          complementary and preventive Medical Center opened in Karaikal,
          Puducherry, India on 11th May 2023 with intention to recover and
          rebuild health of the individual and enrich healthy society.
        </p>
        <p>
          DM Nature cure and wellness center provides Naturopathy, Ozone
          Therapy, Detoxification Therapy, Rejuvenation Therapy, Cupping
          Therapy, Acupuncture Therapy, Mud Therapy, Diet Therapy, Therapeutic 
          Yoga, Underwater Massage, Therapeutic Massage,
          Pain Management, Life style modification etc.
        </p>
        <div className='button-container'>
          <button className='about-read-button' onClick={() => navigate('/about')}>Read More!</button>
        </div>
        
      </div>
    </div>
  );
};

export default AboutPreview;
