import React, { useEffect, useRef } from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import L from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = L.map('contact-map').setView([10.9254417, 79.5495375], 15);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(mapRef.current);

      const marker = L.marker([10.9254417, 79.5495375]).addTo(mapRef.current);
      marker.bindPopup("<b>DM Nature Cure & Wellness Centre</b>").openPopup();

      marker.on('click', () => {
        window.open("https://maps.app.goo.gl/AkKB1nF8dcyEV9yJ7", '_blank');
      });
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column logo-column">
          <img src="/homepage/dm-logo.png" alt="DM Nature Cure Logo" className="footer-logo" />
          <p className="footer-title">DM NATURE CURE AND WELLNESS CENTRE</p>
          <p className="footer-quotes">"Leave your health in safe hands"</p>
        </div>
        <div className="footer-column links-column">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/therapies">Therapies</Link></li>
            <li><Link to="/consultation">Consultation</Link></li>
            <li><Link to="/gallery">Gallery</Link></li>
            <li><Link to="/article">Article</Link></li>
            <li><Link to="/contactUs">Contact Us</Link></li>
            <li><Link to="/privacy-policy">Terms and Condition</Link></li>
            <li><Link to="/cancellation-refund">Cancellation Policy</Link></li>
          </ul>
          <div className="footer-column social-icons-column">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61559933699361" target='_blank' rel='noopener noreferrer'><i className="fab fa-facebook-f"></i></a>
            <a href="https://x.com/dmnaturecure?t=z9CJKKnUP3HyissUbPoNyg&s=09" target='_blank' rel='noopener noreferrer'><i className="fab fa-twitter"></i></a>
            <a href="https://www.instagram.com/_dhanalakshmi_mathan/" target='_blank' rel='noopener noreferrer'><i className="fab fa-instagram"></i></a>
            <a href="https://www.linkedin.com/in/dhana-lakshmi-477910128/" target='_blank' rel='noopener noreferrer'><i className="fab fa-linkedin-in"></i></a>
            <a href="https://www.youtube.com/@Dhanalakshmi-zq9zn" target='_blank' rel='noopener noreferrer'><i class="fa-brands fa-youtube"></i></a>
          </div>
        </div>
        </div>
        <div className="footer-column address-column">
          <h3>Address</h3>
          <p>No. 23, LAKSHMI KALYAN NAGAR, PATCHUR, THIRUNALLAR ROAD, KARAIKKAL – 609 602, PUDUCHERRY, INDIA</p>
          <p><a href="tel:+9186808 88963"><i class="fa fa-phone" aria-hidden="true"></i>   +91 86808 88963</a></p>
          <p><a href="mailto:care@dmnaturecure.com"><i class="fa fa-envelope" aria-hidden="true"></i>  care@dmnaturecure.com</a></p>
          <div id="contact-map" className="map"></div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Copyright © 2024 DM Nature Cure & Wellness Center. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
