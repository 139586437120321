import React, { useState } from 'react';
import './Consultation.css';
import FormOverlay from '../FormOverlay/FormOverlay';
import { useNavigate } from 'react-router-dom';

const Consultation = () => {
    const [showForm, setShowForm] = useState(false);

    const navigate = useNavigate();


    return (
        <div className="consultation-page">
            <div className="header">
                {/* <h1>Consultation</h1>
                <p>Expert guidance for your wellness journey</p> */}
            </div>
            <div className="consultation-card">
                <h2>Naturopathic Consultation</h2>
                <p>In DM Nature Cure the consultation begins with the patient’s interview focusing on finding root cause of disease, past and present medical history, as well as physical examination. The treatment of any health disorder in this system will be through nature.</p>
                <p>Naturopathy believes that intake of drugs/medicines, either to relieve symptoms or cure diseases, produces harmful effect in our body, as it cannot assimilate them naturally. Sometimes, the side effects are more dangerous than the original state of disease itself or they start suffering due to Complications.</p>
                <p>No prescription of synthetic drugs allowed, but considering the health condition of the patient, we help them taper down the doses gradually and will integrate other conventional medical approaches such as Diet, Herbs and lifestyle modification guidelines along with various naturopathic treatments. Mainly the approaches are holistic, promote complete healing and prevent recurrence as well as maintain eminent health throughout their life.</p>
            </div>
            <div className="consultation-options">
                {/* <div className="option direct-consultation">
                    <h2>Direct Consultation</h2>
                    <p>Meet our experts in person for a face-to-face consultation.</p>
                    <button onClick={() => setShowForm(true)}>Learn More</button>
                </div> */}
                <div className="option online-consultation">
                    <h2>Online Consultation</h2>
                    <p>Connect with our experts online from the comfort of your home.</p>
                    <button onClick={() => navigate("/online-consultation")}>Book Your Slot</button>
                </div>
            </div>
        </div>
    );
};

export default Consultation;
