import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './TherapyOverview.css';
import Therapies from './TherapyOverviewContent';

const TherapyOverview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTherapy, setSelectedTherapy] = useState(Therapies[0]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const therapyId = query.get('therapyId');
    const therapy = Therapies.find((t) => t.id === therapyId);
    if (therapy) {
      setSelectedTherapy(therapy);
    }
  }, [location.search]);

  const handleTherapyClick = (id) => {
    navigate(`/therapies?therapyId=${id}`);
    window.scrollTo(0, 0); 
  };

  return (
    <div className="therapy-overview-container">
      <div className="therapy-overview-content">
        <h3>{selectedTherapy.title}</h3>
        <img src={selectedTherapy.imageUrl} alt={selectedTherapy.title} className="therapy-overview-image" />
        {selectedTherapy.description.map((ele, index) => (
          <p key={index}>{ele}</p>
        ))}
        
        {/* Ozone Therapy Special Treatment Section */}
        {selectedTherapy.id === 'ozone' && (
          <div className="therapy-treatment-section">
            <h4>Treatment</h4>
            <div className="therapy-treatment-images">
              <div className="treatment-image-item">
                <h5>Before</h5>
                <img src="/therapies/before.jpeg" alt="Before" />
              </div>
              <div className="treatment-image-item">
                <h5>After</h5>
                <img src="/therapies/after.jpeg" alt="After" />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="therapy-overview-sidebar">
        <h2>Therapies</h2>
        <ul className="therapy-overview-list">
          {Therapies.map((therapy) => (
            <li
              key={therapy.id}
              className={`therapy-overview-item ${selectedTherapy.id === therapy.id ? 'active' : ''}`}
              onClick={() => handleTherapyClick(therapy.id)}
            >
              {therapy.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TherapyOverview;