const Therapies = [
  {
    id: "ozone",
    title: "Ozone Therapy",
    description: [
      "Ozone therapy is a simple, painless process where specific amount of ozone gas is administered through various modes and measures. Ozone helps in increasing energy level in body thereby rejuvenating the cells and produce anti-aging." ,
      " It produces elasticity of tissues by increasing collagen production. It tones and relaxes blood vessels capillaries and increases blood circulation.",
      " It enhances the removal of harmful free radicals, get rid of toxins and promote intake of nutrients by healthy cells. It stimulates entire metabolic process of the cells leading to increase in glucose uptake and oxygen by red blood cells. ",
      "This boost up energy, rejuvenates cells, enhances anti-aging and help oneself to act energetically and efficiently without ailments.",
    ],
    imageUrl:
      "/therapies/Ozone-therapy.jpg",
  },
  {
    id: "detox",
    title: "Detoxification Therapy",
    description: [
      "DETOX IS THE MAIN PART OF HEALING",
      "We are constantly exposed to toxins in our daily living through external factor like urban environment, climate and chemical exposure etc. Internal elements such as consuming artificial flavours and chemicals in foods, and in the form of packed foods, resulting in end products like free radicals are also considered to be toxins.",
      " The quality of blood is affected when toxins are present in blood stream which entered either through digestive system or respiratory system or any other way. The blood has to possess certain alkaline pH for perfect maintenance of health. This alkalinity may be lost due to bad life style, irregular food habits, sleeping disorder etc. ",
      "Here various natural therapeutic modalities are used for the purpose of effective detoxification. Detoxification is said to be the main part of the treatment, which attempts in getting rid of various impurities and toxins, which are often the major cause of any illness.",
    ],
    imageUrl:
      "/therapies/detox.jpg",
  },
  {
    id: "rejuvenation",
    title: "Rejuvenation Therapy",
    description: [
      "Rejuvenation Therapy aims to stimulate the body’s natural healing process using various non conventional methods. Some of the natural therapies including nutrition, herbal medicine and physical therapies to stimulate body’s self healing process. ",
      "It also helps to remove toxins from the body, boost the immune system and restore normal function of the body. Rejuvenation helps to prevent disease and organ damages. By this therapy quality of the life gets improved.",
    ],
    imageUrl:
      "/therapies/rejinuvation.jpg",
  },
  {
    id: "cupping",
    title: "Cupping Therapy",
    description: [
      "Cupping is a method of creating high negative pressure on the skin by suction pumps which leads to increased blood volume, increased capillary filtration rate and expulsion of filtered matter in the interstial spaces. ",
      "This filtered matter containing disease causing substances called prostaglandins and inflammatory mediators are removed in wet cupping process thereby promoting therapeutic effects. Dry cupping process applied at specific points, creates improved blood circulation and enhances removal of toxins on its own. ",
      "It is a complementary therapy to enhance health and promote wellbeing.",
    ],
    imageUrl:
      "/therapies/cub-therapy.jpg",
  },
  {
    id: "acupuncture",
    title: "Acupuncture Therapy",
    description: [
      "Acupuncture helps in correcting imbalances of energy in the body, thereby helps in treating acute and chronic diseases. It also improves general health.",
      " The acupuncture points stimulate the nerves, muscles, connective tissues thereby increase blood circulation and triggers neuro transmitter production of dopamine, serotonin and oxytocin that helps in relieving stress and pain. ",
      "It helps in maintaining overall health by enhancing the immune  property.",
    ],
    imageUrl:
      "/therapies/accupunture.jpg",
  },
  {
    id: "diet",
    title: "Diet Therapy",
    description: [
      "Fasting is the superior method of healing for various ailments. In therapeutic fasting, specific juices/soups are given as per disease condition. ",
      "It helps in eliminating toxins by giving rest to the digestive system and enhances the healing power from within.We provide a balanced nutrition that supports healing enhances well-being and helps to prevent further health issues. ",
      "We strongly believe “Let Food be thy Medicine & Medicine be thy food”",
    ],
    imageUrl:
      "/therapies/diet.jpg",
  },
  {
    id: "mud",
    title: "Mud Therapy",
    description: [
      "Mud is one of the five elements of nature having immense impact on health as well as in curing sickness.",
      " Mud has therapeutic effect due to its mineral content and the ability to retain moisture and heat. ",
      "Mud packs, Face masks and Mud baths are some of the application method we adopt to rejuvenate  body to treat various health conditions and promote overall well being",
    ],
    imageUrl:
      "/therapies/mud-therapy.jpg",
  },
  {
    id: "therapeutic",
    title: "Therapeutic Yoga",
    description: [
      "Application of Therapeutic Yoga techniques address specific health issues and improve overall well being by addressing physical, mental and emotional imbalances. ",
      "Different kinds of yoga asana, pranayama, mudras, bandhas, and meditation will also be taught for hassle free living. ",
      "To eradicate diseased state therapeutic yoga will be taught based on their condition.",
    ],
    imageUrl:
      "/therapies/theraupatic-yoga.jpg",
  },
  {
    id: "underwater",
    title: "Underwater Massage",
    description: [
      "Underwater massage uses water pressure to massage the body. This therapy is performed in special hydrotherapy equipment and offers various health benefits. ",
      "The buoyancy of the water reduces the impact of gravity and allows more gentle and effective massage.",
      " It helps in relieving pain, improve circulation, muscle relaxation, stress reduction and enhanced mobility. ",
    ],
    imageUrl:
      "/therapies/underwater-therapy.jpg",
  },
  {
    id: "therapeutic massage",
    title: "Therapeutic Massage",
    description: [
      "Therapeutic Massage helps to focus on treating musculoskeletal problems and improving overall health and well being. ",
      "It reduce stress and address health issues. Various techniques used to manipulate soft tissues of the body for better blood circulation and detoxification.  ",
    ],
    imageUrl:
      "/therapies/Therapatic-Massage.jpg",
  },
  {
    id: "pain",
    title: "Pain Management",
    description: [
      "Various methods of pain management strategies followed at our center based on the condition. ",
      "Identifying the cause and healing the root is the primary focus. For this Ozone therapy, nutritional therapy, life style modification, mind body therapy, detoxification etc. involves in altering the state of pain. ",
    ],
    imageUrl:
      "/therapies/pain-manage.jpg",
  },
  {
    id: "stress",
    title: "Stress Management",
    description: [
      "Relieving oneself from stress and tension is the utmost important tool for healthy living. ",
      "Here especially Deep relaxation techniques are helpful tool for coping up with day to day stress.",
    ],
    imageUrl:
      "/therapies/stress-management.jpg",
  },
  {
    id: "antiaging",
    title: "Anti-Aging ",
    description: [
      "A prolonged state of inflammation caused by stress, poor nutritional diet, any chronic disease, alcohol and smoking can damage the elastin fibers that determine the skin’s strength and firmness. This damage causes aging by creating loose, sagging skin and wrinkles.",
      "There are many types of therapies that can slow down aging and increase longevity. One of the biggest factors in unhealthy aging is the surplus of inflammatory chemicals. ",
      "Naturopathic diet and therapies help in reducing inflammation, modify nutritional imbalances, enhances functional efficiency by detoxifying the systems internally and bring about beauty and luster to the skin externally."
    ],
    imageUrl:
      "/therapies/Anti-Aging.jpg",
  },
  {
    id: "meditation",
    title: "Meditation",
    description: [
      "Naturopathic diet and therapies help in reducing inflammation, modify nutritional imbalances, enhances functional efficiency by detoxifying the systems internally and bring about beauty and luster to the skin externally.",
      "Majority of diseases are caused due to hasty lifestyle, undergoing stressful situations within families and workplace, peer pressure in competitive world, etc.",
      "Diseases are either caused or aggravated due to such negative impacts. Meditation has plenty of benefits like relieving stress and tension, removing nervous weakness, increasing memory power and creative thinking, helping to develop problem-solving skills, promoting emotional health, etc."  
    ],
    imageUrl:
      "/therapies/yoga.jpg",
  },
];

export default Therapies;
