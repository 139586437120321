import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './TestimonialSection.css';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const testimonials = [
  {
    name: 'Stella Larson',
    date: 'Monday, May 2022',
    text: 'Proin sed libero enim sed faucibus turpis. At imperdiet dui accumsan sit amet nulla facilisi morbi tempus. Ut sem nulla pharetra diam sit amet nisl.',
    image: 'https://via.placeholder.com/100',
  },
  {
    name: 'Nick Johnson',
    date: 'Monday, June 2022',
    text: 'Proin sed libero enim sed faucibus turpis. At imperdiet dui accumsan sit amet nulla facilisi morbi tempus. Ut sem nulla pharetra diam sit amet nisl.',
    image: 'https://via.placeholder.com/100',
  },
  {
    name: 'Olga Ivanova',
    date: 'Monday, May 2022',
    text: 'Proin sed libero enim sed faucibus turpis. At imperdiet dui accumsan sit amet nulla facilisi morbi tempus. Ut sem nulla pharetra diam sit amet nisl.',
    image: 'https://via.placeholder.com/100',
  },
  {
    name: 'Nick Johnson',
    date: 'Monday, June 2022',
    text: 'Proin sed libero enim sed faucibus turpis. At imperdiet dui accumsan sit amet nulla facilisi morbi tempus. Ut sem nulla pharetra diam sit amet nisl.',
    image: 'https://via.placeholder.com/100',
  },
  {
    name: 'Stella Larson',
    date: 'Monday, May 2022',
    text: 'Proin sed libero enim sed faucibus turpis. At imperdiet dui accumsan sit amet nulla facilisi morbi tempus. Ut sem nulla pharetra diam sit amet nisl.',
    image: 'https://via.placeholder.com/100',
  },
];

const TestimonialSection = () => {
  return (
    <div className="testimonial-section">
      <h2>What People Say?</h2>
      <Swiper
        modules={[Autoplay, Pagination, Navigation]}
        spaceBetween={20}
        slidesPerView={2}
        loop={true}
        autoplay={{
          // delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }}
        pagination={{ clickable: true }}
        navigation={true}
        breakpoints={{
          320:{
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 2,
          },
        }}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div className="testimonial">
              <img src={testimonial.image} alt={testimonial.name} />
              <div className="testimonial-content">
                <p className="quote-icon">“</p>
                <p>{testimonial.text}</p>
                <h3>{testimonial.name}</h3>
                <p className="date">{testimonial.date}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TestimonialSection;
