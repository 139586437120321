import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { X, CalendarClock } from "lucide-react";
import "./Landing.css";
import ContentSection from "../ContentSection/ContentSection";
import AboutPreview from "../About_Preview/AboutPreview";
import TherapyList from "../TherapySection/TherapyList";
import LogoCarousel from "../Logo_Carousel/LogoCarousel";
import MemberSlider from "../Member_Slider/MemberSlider";
import GalleryPage from "../GalleryPage/GalleryPage";
import BenefitsOfNaturo from "../BenefitsOfNaturo/BenefitsOfNaturo";
import TestimonialSection from "../Testimonials/TestimonialSection";
import GalleryPageNav from "../../GalleryPageNav/GalleryPageNav";
import ArticleSection from "../ArticleSection/ArticleSection";
import VideoSection from "../VideoSection/VideoSection";
import DiseasesTreatedSection from "../DiseasesTreatedSection/DiseasesTreatedSection";
//import banner from "../../../../public/logo/banner.jpeg";

const LandingPage = ({ setShowConsultationButton, setShowModal, showModal }) => {
  const [showBanner, setShowBanner] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const modalShown = sessionStorage.getItem("modalShown");

    const timer = setTimeout(() => {
      if (!modalShown || modalShown !== "closed") {
        setShowBanner(true);
        setShowModal(true);
        setShowConsultationButton(false);
      }
    }, 10000); // Show banner after 10 seconds

    return () => clearTimeout(timer); // Clean up the timer if the component unmounts
  }, [setShowModal, setShowConsultationButton]);

  const handleDownloadClick = () => {
    setShowModal(false);
    setShowConsultationButton(true);
    sessionStorage.setItem("modalShown", "closed");
    navigate("/online-consultation");
  };

  const closeModal = () => {
    setShowModal(false);
    setShowConsultationButton(true);
    sessionStorage.setItem("modalShown", "closed");
  };

  const backgroundImageUrl = "/homepage/beautiful-scenery-river-surrounded-by-greenery-forest_1_11zon.jpg";
  //const bannerUrl = "https://i.ibb.co/XtFs8ty/banner-image-new.jpg";
  const bannerUrl = "/homepage/banner.jpeg";

  const divStyle = {
    backgroundImage: `url(${backgroundImageUrl})`,
  };

  const bannerStyle = {
    backgroundImage: `url(${bannerUrl})`,
    borderRadius: "10px",
  };

  return (
    <div>
      {showModal && showBanner && (
        <div className="fixed inset-0 bg-black bg-opacity-10 backdrop-blur-sm z-50 flex justify-center items-center">
          <div
            style={bannerStyle}
            className="banner mt-10 flex flex-col gap-5 text-white"
          >
            <button onClick={closeModal} className="place-self-end">
              <X size={30} />
            </button>
            <div className="overall-btn-container">
              <div className="book-btn-container rounded-xl">
                <button
                  type="button"
                  onClick={handleDownloadClick}
                  className="book-btn bg-black text-white rounded-md "
                >
                  <CalendarClock />
                  Book Your Slot
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={showModal && showBanner ? "landing-page blurred" : "landing-page"}
        style={divStyle}
      >
        <div className="overlay"></div>
        <div className="content-grid">
          <div className="content-container">
            <div className="content-heading">
              <h2 className="heading">DM Nature Cure</h2>
              <p className="and-symbol">&</p>
              <h2 className="heading">Wellness Centre</h2>
            </div>
            <p className="description">
              DM Nature cure and wellness centre exclusively uses the system of
              Naturopathy and Yoga as its treatment modality, which helps in
              prevention and cure of acute and chronic diseases through body
              detoxification and lifestyle modification with a holistic
              approach.
            </p>
          </div>
        </div>
      </div>
      <AboutPreview />
      <TherapyList />
      <BenefitsOfNaturo />
      <DiseasesTreatedSection />
      <LogoCarousel />
      <MemberSlider />
      <GalleryPage />
      <ArticleSection />
    </div>
  );
};

export default LandingPage;
