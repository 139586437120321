import React from 'react';
import './DiseasesTreatedSection.css';

const DiseasesTreatedSection = () => {
  const diseases = [
    { id: 1, name: "OBESITY", imageUrl: "https://i.ibb.co/9yLTj3W/illustration-of-overweight-boy-world-obesity-day-great-for-banners-posters-or-templates-vector-1.jpg", description: "" },
    { id: 2, name: "LIFESTYLE DISORDER", imageUrl: "https://www.shutterstock.com/image-vector/illustration-that-man-who-risk-260nw-2226867925.jpg", description: "" },
    { id: 3, name: "PSORIASIS", imageUrl: "https://cdn5.vectorstock.com/i/1000x1000/43/94/psoriasis-on-human-hand-white-background-vector-27254394.jpg", description: "" },
    { id: 4, name: "ARTHRITIS", imageUrl: "https://as2.ftcdn.net/v2/jpg/02/78/43/23/1000_F_278432323_SYgyHhDovqDmFFUUJa7dJAJZMGlTVoBo.jpg", description: "" },
    { id: 5, name: "ANAEMIA", imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7Dg6jdLCCVuPBxvqP0SqkkYu6uBMRylXkgw&s", description: "" },
    { id: 6, name: "SCIATICA", imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQi0iLnIEzlYlAhvfhoWGRiVB_B9BFs8k8lug&s", description: "" },
    { id: 7, name: "MENSTRUAL DISORDERS", imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStgGOsmFXntL7lpg_6MYC8d6OcBbd5r_WOPQ&s", description: "" },
    { id: 8, name: "PRE AND POST-MENOPAUSAL SYNDROME", imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzCZYsIsj4wBlyeT7RIby3HUQlO6FTQ92-TQ&s", description: "" },
  ];

  return (
    <div className="diseases-treated-section">
      <h2>Diseases Treated</h2>
      <div className="diseases-cards-container">
        {diseases.map((disease) => (
          <div key={disease.id} className="disease-card">
            <img src={disease.imageUrl} alt={disease.name} className="disease-image" />
            <h3>{disease.name}</h3>
            <p>{disease.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DiseasesTreatedSection;