import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import './LogoDetails.css';

const logoImages = [
  {
    src: "https://i.ibb.co/F6NNp8b/Nhra-license-front-and-back-new.jpg",
    title: "NHRA",
    description: "visit - www.nhra.bh",
    link:"https://www.nhra.bh/",
  },
  {
    src: "https://i.ibb.co/Sdzw6yF/Dhanalakshmi-Central-Registration-certificate-page-0001-1.jpg",
    title: "CCRYN",
    description: "visit - www.ccryn.com",
    link:"https://namayush.gov.in/content/central-council-research-yoga-naturopathy-ccryn",
  },
  {
    src: "https://i.ibb.co/Yk5cT5L/Bnys-1.jpg",
    title: "TNBIM",
    description: "visit - www.tnbim.com",
    link:"https://tnhealth.tn.gov.in/tngovin/dimh/dimh.php",
  },
  {
    // src: "https://i.ibb.co/Yk5cT5L/Bnys-1.jpg",
    title: "NRB",
    description: "visit - www.nrb.net.in",
    link:"https://www.nrb.net.in/",
  }
];

const logoDetailsData = {
  logo1: {
    title: "Logo 1 Title",
    description: "Description for Logo 1",
    index: 0,
  },
  logo2: {
    title: "Logo 2 Title",
    description: "Description for Logo 2",
    index: 1,
  },
  logo3: {
    title: "Logo 3 Title",
    description: "Description for Logo 3",
    index: 2,
  },
  logo4: {
    title: "Logo 4 Title",
    description: "Description for Logo 4",
    index: 3,
  },
};

const LogoDetails = () => {
  const { logoId } = useParams();
  const logoInfo = logoDetailsData[logoId];
  const { title, description, index } = logoInfo;

  const [highlightedIndex, setHighlightedIndex] = useState(index);
  const imageRefs = useRef([]);

  useEffect(() => {
    if (highlightedIndex !== null && imageRefs.current[highlightedIndex]) {
      const timer = setTimeout(() => {
        const highlightedImage = imageRefs.current[highlightedIndex];
        const offset = 100; 
        const elementPosition = highlightedImage.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [highlightedIndex]);

  return (
    <div className="logo-details-container" key={logoId}>
      <div className="logo-header">
      </div>
      <div className="logo-image-section">
        {logoImages.map((img, idx) => (
          <div
            key={`${logoId}-${idx}`}
            ref={(el) => (imageRefs.current[idx] = el)}
            className={`logo-image ${idx === highlightedIndex ? 'highlight' : ''}`}
          >
            <h3>{img.title}</h3>
            <Zoom
              zoomImg={{ className: 'zoomImg' }}
              zoomMargin={20}
              overlayBgColorEnd="rgba(0, 0, 0, 0.9)"
            >
              <img src={img.src} alt={`Logo Image ${idx + 1}`} />
            </Zoom>
            <a href={img.link} target="_blank">{img.description}</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoDetails;
